<template>
  <!-- Error page-->
  <div>
    <div class="misc-wrapper" v-if="show404">
      <b-link class="brand-logo">
        <h2 class="brand-text text-danger ml-1">
          {{ $t('Pages.Error.Title.Mastertube') }}
        </h2>
      </b-link>
  
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <b-img :src="imgUrl" alt="Error page" style="width: 85%;" />
          <h2 class="mb-1 pt-2">
            {{ $t('Pages.Error.Title.not_found') }} 🕵🏻‍♀️
          </h2>
          <h5>{{                                        $t("Pages.Error.Title.auto_redirect")                                        }}</h5>
  
          <h2 class="mb-1">
            <b-button variant="primary" class="mb-2 btn-sm-block mt-1" :to="{ path: '/' }">
              {{ $t('Pages.Error.Button.Dashboard') }}
            </b-button>
          </h2>
  
        </div>
      </div>
    </div>
    <div class="misc-wrapper" v-if="!show404">
        <b-link class="brand-logo">
          <h2 class="brand-text text-danger ml-1">
            {{ $t('Pages.Error.Title.Mastertube') }}
          </h2>
        </b-link>
    
        <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <b-img :src="imgUrl" alt="Error page" style="width: 85%;" />
            <h2 class="mb-1 pt-2">
              {{ $t('Pages.Error.Title.wellcome_msg') }}
            </h2>
            <h5>{{  $t("Pages.Error.Title.please_wait") }}</h5>
    
          </div>
        </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      routes: [],
      show404: false
    }
  },
  created() {
    if (this.$route.redirectedFrom) {
      let code = this.$route.redirectedFrom.split("/")[1];
      this.$router.options.routes.forEach(route => {
        this.routes.push(route.path)
      })
      if (!this.routes.includes(code)) {
        const payload = {
          referral_token: code
        };
        this.$http
          .post(`/api/user/referral/check_exist`, payload)
          .then((response) => {
            if (response.data.status) {
              localStorage.setItem("mastertube_referral", code)
              window.open(process.env.VUE_APP_LandingReferral, "_self")
            } else {
              this.show404 = true
              setTimeout(() => {
                this.$router.push("/")
              }, 2000);
            }
          })
      } else {
        this.show404 = true
        setTimeout(() => {
          this.$router.push("/")
        }, 2000);
      }
    } else {
      this.show404 = true
      setTimeout(() => {
        this.$router.push("/")
      }, 3000);
    }


  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
